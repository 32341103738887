<template>
  <span>P#{{ getAccount() }}</span>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrdersTableCount",

  data() {
    return {
      data: {},
    };
  },

  methods: {
    getAccount() {
      return get(this.data, "porder");
    },
  },
};
</script>
